<template>
<div class="">

    <div class="market-profile-mobile-pages">
        <slot v-if="market_header_info">
            <div class="market-symbol-row" v-if="market_header_info.title.length < 24">
                <div class="market-symbol-row-right">
                    <div class="market-symbol-avatar" :style="avatarFinder"></div>
                    <div class="market-symbol-info">
                        <h1 class="market-symbol-title">{{ market_header_info.title }}</h1>
                        <span class="market-symbol-title-en">
                            {{ market_header_info.title_en }}
                            <span class="market-symbol-market">{{ market_header_info.category_title}}</span>
                        </span>
                    </div>
                </div>
                <div class="market-symbol-row-left">
                    <div class="market-symbol-info">
                        <div class="market-symbol-price">
                            <span class="price" data-col="info.last_trade.PDrCotVal">{{ market_header_summary.price.value }}</span>
                        </div>
                        <span class="market-symbol-change">
                            <span class="change-tag" data-col="info.last_trade.last_change_percentage">
                                <span :class="market_header_summary.yesterday_dt.plain">{{market_header_summary.yesterday_dp.plain}}</span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            
            <div class="market-symbol-row market-symbol-row-long" v-else>
                <div class="market-symbol-row-right">
                    <div class="market-symbol-avatar" :style="avatarFinder"></div>

                        <div class="market-symbol-info">
                            <h1 class="market-symbol-title">{{ market_header_info.title }}</h1>
                            <div class="market-symbol-info-flex">
                                <span class="market-symbol-title-en">
                                    <span class="market-symbol-market-e-t">{{ market_header_info.title_en }}</span>
                                    <span class="market-symbol-market">{{ market_header_info.category_title}}</span>
                                </span>
                                
                                <div class="market-symbol-row-left">
                                    <div class="market-symbol-info">
                                        <div class="market-symbol-price">
                                            <span class="price" data-col="info.last_trade.PDrCotVal">{{ market_header_summary.price.value }}</span>
                                        </div>
                                        <span class="market-symbol-change">
                                            <span class="change-tag" data-col="info.last_trade.last_change_percentage">
                                                <span :class="market_header_summary.yesterday_dt.plain">{{market_header_summary.yesterday_dp.plain}}</span>
                                            </span>
                                        </span>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </slot>
        <div class="market-symbol-row" v-else>
            <div class="text-center p-2" style="flex-grow: 1;">
                <div class="widget-loading"></div>
            </div>
        </div>
    </div>
    <div class="market-slider-tabs-box" style="border-top: 1px solid #ececec;">
        <div class="mobile-back-btn" v-on:click="back()">
            <i class="uil uil-angle-right-b"></i>
        </div>
        <div class="market-slider-tabs">
            <template>
                <v-card>
                    <v-tabs >
                        <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                        <v-tab v-for="(item, index) in marketNavPage" :key="index" v-if="!item.hide" :to="{name: item.name, params: { name: $route.params.name }, }">
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </v-tab>
                    </v-tabs>
                </v-card>
            </template>
        </div>
    </div>

</div>



</template>

<script>
import marketNavPage from "@/assets/static_market_nav_page";

export default {
    name: 'MarketHeader',
    props: ['data'],
    data() {
        return {
            market_header_info: null,
            market_header_summary: null,
            marketNavPage,
        }
    },
    mounted() {
        if (this.$store.state.marketHeader && this.$store.state.marketHeader.name == this.data) {
            this.market_header_info = this.$store.state.marketHeader.info;
            this.market_header_summary = this.$store.state.marketHeader.summary;
        } else {
            this.getProfileHeader();
        }
    },
    destroyed() {
        $('body').removeClass('internal profile fs-grid');
    },
    computed: {
        // آواتار یک شاخص را پیدا می کند
        avatarFinder: function () {
            if (this.market_header_info.key.includes('crypto-')) {
                return  {
                    'position' : 'relative',
                    'background-image': 'url(https://static.tgju.org/images/crypto/icons/' + this.market_header_info.key.replace('crypto-', '') + '_' + this.market_header_info.grade.toLowerCase() + '.png',
                }
            } else if (this.market_header_info.image) {
                return  {
                    'position' : 'relative',
                    'background-image': 'url(https://platform.tgju.org/files/images/' + this.market_header_info.image + ')',
                }
            } else if ( this.market_header_info.country_code && this.market_header_info.country_code != 'wd'){
                return  {
                    'position' : 'relative',
                    'background-size': '140%',
                    'background-image': 'url(https://www.tgju.org/images/flags/4x3/' + this.market_header_info.country_code + '.svg)',
                }
            }

            return  {
                'position' : 'relative',
                'background-image': 'url(https://static.tgju.org/images/no-icon.png)',
            }
        },
    },
    methods: {
        getProfileHeader() {
            this.$helpers
                .makeRequest("GET", `/market/profile-header/${this.data}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_header_info = api_response.data.response.detail.response.info;
                        this.market_header_summary = api_response.data.response.detail.response.summary;
                        this.$store.commit('setMarketHeader', {
                            name : this.data,
                            info : this.market_header_info,
                            summary : this.market_header_summary
                        });
                        $('.stocks-profile').append('<link rel="stylesheet" type="text/css" href="https://static.tgju.org/components/tgju-fix/fix-custom.css">');
                        $('body').addClass('internal profile fs-grid mprofile');
                    }
                });
        },
        // دکمه بازگشت
        back() {
            this.$router.go(-1);
        },
    },
}
</script>